<template>
   <v-expand-transition>
      <v-form ref="form">
         <div v-if="typeof option.id === 'string' || option.id >= 0">
            <v-card-text class="pt-9">
               <v-row>
                  <v-col cols="12" md="6">
                     <v-text-field v-model="form.name" label="Management Name" placeholder="Nama Manajemen" outlined hide-details required :rules="[v => !!v]">
                        <template slot="append-outer">
                           <v-btn tile large height="56" width="56" icon @click="$refs.optionLogo.click()">
                              <v-avatar v-if="form.Logo" tile size="56">
                                 <img :src="setUrlFromImage(form.Logo)" alt="Logo" style="object-fit: cover;" @error="() => { form.Logo = '' }">
                              </v-avatar>
                              <v-icon v-else tile size="68">
                                 mdi-image
                              </v-icon>
                              <input ref="optionLogo" type="file" accept="image/*" alt="optionLogo" style="display:none;" @change="attachmentPreview">
                           </v-btn>
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.zone" label="Zone" placeholder="Zona Organisasi" outlined hide-details required :rules="[v => !!v && /^[A-Z0-9]{3}$/.test(v)]" />
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field v-model="form.domain" label="Domain" placeholder="e.g. nvm.nirtara.com" outlined hide-details required :rules="[v => !!v]" />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
            <v-card-text class="pb-6">
               <v-row class="mt-0">
                  <v-col cols="12" class="py-0 d-flex">
                     <v-btn v-if="$hasRole(option.kind.toLowerCase(), 'CU')" x-large color="accent" depressed class="mr-4" :loading="$store.state.process === 'updateOption'" @click="updateRecord">
                        Update
                     </v-btn>
                     <v-btn x-large color="accent" depressed outlined class="mr-4" @click="option.id = -1">
                        {{ $store.state.process === 'updateOption' ? 'Close' : 'Cancel' }}
                     </v-btn>
                     <v-spacer />
                  </v-col>
               </v-row>
            </v-card-text>
            <v-divider />
         </div>
      </v-form>
   </v-expand-transition>
</template>

<script>
import { imageFromUrl, blobImageToBase64 } from '@/utils/nirtara'
const empty = { Logo: '', kind: 'Management', zone: '', name: '', domain: '' }
export default {
   name: 'NManagementForm',

   props: {
      option: { type: Object },
   },
   data: () => ({
      isUsed: false,
      form: Object.assign({}, empty),
   }),

   computed: {
      setUrlFromImage () {
         return image => imageFromUrl(image)
      },
   },
   watch: {
      'option.id' (id) {
         if (id < 0) return
         id === 0 && this.$refs.form.reset()
         this.form = Object.assign({}, empty, typeof id === 'string' ? this.option : { id: 0 })
      },
      'form.zone' (zone) {
         this.form.zone = zone.toUpperCase()
      },
   },
   methods: {
      attachmentPreview (event) {
         blobImageToBase64(this.$refs[event.target.alt].files[0])
         .then(base64 => {
            switch (event.target.alt) {
               default: console.log(`${event.target.alt}: ${base64.substr(0, 32)}....`)
                  break
               case 'optionLogo': this.form.Logo = base64
                  break
            }
         })
      },
      updateRecord () {
         if (!this.$refs.form.validate()) return
         this.$emit('update', this.form)
      },
   },
}
</script>

<style lang="scss" scoped>
.col-12 {
   padding: 0 12px 20px 12px;
}
::v-deep .v-text-field--outlined .v-input__append-outer {
   margin: auto 0 auto 16px;
}
.v-btn:not(.v-btn--round).v-size--x-large {
   min-width: 115px;
}
</style>
